import '../css/app.css'
import 'lazysizes'
import Alpine from 'alpinejs'
import baguetteBox from 'baguettebox.js'
import Parallax from 'parallax-js'
import './cookie3.js'

window.Alpine = Alpine

Alpine.data('map', () => ({
  items: [],
  filter: 0,
  init (locations) {
    this.items = locations
  },
  get result () {
    if (this.filter === 0) {
      return this.items
    } else {
      return this.items.filter((item) => item.cat.includes(this.filter))
    }
  },
  setFilter (catId) {
    this.filter = this.filter == catId ? 0 : catId
  },
}))

Alpine.start()

document.addEventListener('DOMContentLoaded', () => {
  baguetteBox.run('.baguette', {
    noScrollbars: true,
    async: true,
  })

  const bubbles = document.querySelectorAll('.bubble')
  if (bubbles.length > 0) {
    for (let i = 0; i < bubbles.length; i++) {
      new Parallax(bubbles[i], {
        pointerEvents: true,
        clipRelativeInput: true,
        invertX: false,
        invertY: false,
      })
    }
  }
})
